'use strict'

function contractService ($q, $http, $localStorage) {

  return {
    getContracts: getContracts,
    getContractsByClientId: getContractsByClientId,
    setHeaders: setHeaders
  };

  function getContracts () {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CONTRACTS;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      if (response.status === 200) {
        def.resolve(response.data);
      }
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function getContractsByClientId (clientId) {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CONTRACTS_CLIENT + clientId;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function setHeaders() {
    var headers = {
      'Authorization': 'Bearer ' + $localStorage.id_token
    };
    return headers;
  }

}

module.exports = contractService;
