'use strict';

function clientService($q, $http, $localStorage, angularAuth0) {

  //DEPRECATED
  function Login(username, password) {
    var def = $q.defer();
    var endpoint = CONFIG.AUTH_GLP.RO;
    var data = {
      "grant_type": CONFIG.AUTH_GLP.GRANT_TYPE,
      "client_id": CONFIG.AUTH_GLP.CLIENT_ID,
      "username": username,
      "password": password,
      "connection": CONFIG.AUTH_GLP.CONNECTION,
      "redirect_uri": CONFIG.AUTH_GLP.REDIRECT_URI_SERVER2,
      "scope": CONFIG.AUTH_GLP.SCOPE
    };
    $http.post(endpoint, data).then(function (response) {
      $localStorage.access_token = response.data.access_token;
      $localStorage.id_token = response.data.id_token;
      return getUserInfo();
    }).then((response) => {
      setUserData(response.data);
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function loginTest(user, password) {
    var def = $q.defer();
    var endpoint = CONFIG.AUTH_GLP.TOKEN;
    var data = {
      "realm": CONFIG.AUTH_GLP.CONNECTION,
      "username": user,
      "password": password,
      "scope": CONFIG.AUTH_GLP.SCOPE,
      "grant_type": "http://auth0.com/oauth/grant-type/password-realm",
      "redirect_uri": CONFIG.AUTH_GLP.REDIRECT_URI_SERVER2,
      "glpera-api-identifier": CONFIG.AUTH_GLP.AUDIENCE,
      "client_id": CONFIG.AUTH_GLP.CLIENT_ID
    };
    $http.post(endpoint, data).then(function (response) {
      $localStorage.access_token = response.data.access_token;
      $localStorage.id_token = response.data.id_token;
      def.resolve(response)
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function login2(user, password) {
    let def = $q.defer();
    angularAuth0.client.login({
      realm: CONFIG.AUTH_GLP.CONNECTION,
      username: user,
      password: password,
      scope: CONFIG.AUTH_GLP.SCOPE,
      response_type: CONFIG.AUTH_GLP.RESPONSE_TYPE,
      sso: CONFIG.AUTH_GLP.SSO,
      redirectUri: CONFIG.AUTH_GLP.REDIRECT_URI_SERVER2,
      audience: CONFIG.ENDPOINTS.USER_INFO
    }, function (err, authResult) {
      if (authResult && authResult.accessToken && authResult.idToken) {
        $localStorage.access_token = authResult.accessToken;
        $localStorage.id_token = authResult.idToken;
        def.resolve(authResult);
      } else if (err) {
        def.reject(err);
      }
    });
    return def.promise;
  }

  function setUserData(userdata) {
    $localStorage.user_created_at = userdata[CONFIG.AUTH_GLP.USER_CREATED_AT];
    $localStorage.user_email = userdata.name;
    $localStorage.user_name = userdata.name;
    $localStorage.user_nickname = userdata.nickname;
    $localStorage.user_nickname = userdata.nickname;
    $localStorage.user_picture = userdata.picture;
    var itsClient = false;
    for (var n = 0; n < userdata[CONFIG.AUTH_GLP.USER_ROLE_URL].roles.length; n++) {
      if (userdata[CONFIG.AUTH_GLP.USER_ROLE_URL].roles[n] === "CLIENT") {
        itsClient = true;
      }
    }
    if (itsClient) {
      $localStorage.user_role = 'CLIENT';
    } else {
      $localStorage.user_role = 'ADMIN';
    }
  }

  function setClientData(clientdata) {
      $localStorage.client_dni = clientdata.dni;
      $localStorage.client_email = clientdata.email;
      $localStorage.client_name = clientdata.nombre;
      $localStorage.client_lastname = clientdata.apellidos;
      $localStorage.client_fax = clientdata.fax;
      $localStorage.client_phone1 = clientdata.telefono1;
      $localStorage.client_phone2 = clientdata.telefono2;
      $localStorage.client_phone3 = clientdata.telefono3;
    }

  function getUserInfo() {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.USER_INFO;
    var config = {
      headers:  {
        'Authorization': 'Bearer ' + $localStorage.access_token
      }
    };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      if(err.data == "Unauthorized"){
        def.resolve(err);
      }
      def.reject(err);
    });
    return def.promise;
  }

  function getClient() {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CLIENT_INFO;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function getClientById(clientId) {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CLIENT_BY_ID + clientId;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function postClient(id_type, id, contract_type, contract, email, password) {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CLIENT_POST;
    var data = {
      tipo_identificacion: id_type,
      identificacion: id,
      tipo_contrato: contract_type,
      contrato: contract,
      email: email,
      usr_password: password
    };
    $http.post(endpoint, data).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function updatePassword(pwd) {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CLIENT_UPDATE_PASSWORD;
    var data = {
      'usr_password': pwd
    };
    var config = { headers: setHeaders() };
    $http.post(endpoint, data, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function changePassword(email) {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CLIENT_CHANGE_PASSWORD;
    var data = {
      email: email
    };
    $http.post(endpoint, data).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function deleteUser() {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS.CLIENT_DELETE;
    var config = { headers: setHeaders() };
    $http.delete(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function setHeaders() {
    var headers = {
      'Authorization': 'Bearer ' + $localStorage.id_token
    };
    return headers;
  }

  return {
    Login: Login,
    getUserInfo: getUserInfo,
    getClient: getClient,
    getClientById: getClientById,
    postClient: postClient,
    updatePassword: updatePassword,
    changePassword: changePassword,
    deleteUser: deleteUser,
    setHeaders: setHeaders,
    setUserData: setUserData,
    login2: login2,
    setClientData: setClientData,
    loginTest: loginTest
  };

}

module.exports = clientService;
